import React, { useEffect, useReducer, useState } from "react";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import {
  Alert,
  Box,
  Card,
  Fade,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  FormHelperText,
  Modal,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Validations, { getCookie } from "src/utils/validations";
import { CLIENT_IS_LOGGED_IN } from "src/utils/queries";
import { Link } from "gatsby";
import { Icon, Logo, Button } from "src/templates";
import Settings from "src/utils/Settings";

const validations = new Validations();

const initialState = {
  message: {
    text: "",
    type: undefined,
  },
  loading: false,
  values: {
    email: "",
    password: "",
  },
  errors: {
    error_email: false,
    error_password: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "setError":
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.error,
        },
      };
    case "setState":
      return {
        ...state,
        ...action.state,
      };
    case "setValue": {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.value,
        },
        errors: {
          ...state.errors,
          ...validations.validateField(action.value),
        },
      };
    }
    default:
      throw new Error();
  }
}

export default function WrappedPage() {
  return <Login />;
}

const Login = ({ location }) => {
  const theme = useTheme();

  const styles = {
    formGroupTitle: {
      paddingTop: "30px",
      paddingBottom: "6px",
      fontSize: "12px",
      fontWeight: "bold",
      fontFamily: "Lato",
    },
    disclaimer: {
      lineHeight: "15px",
      fontSize: "12px",
      fontStyle: "italic",
      padding: "6px 6px 6px 0px",
    },
    cumploIcon: {
      background: "white",
      borderRadius: "100%",
      margin: "0 auto",
      position: "relative",
      width: "60px",
      height: "60px",
      zIndex: 3,
      boxShadow: "0px 2px 10px #00000029",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    registerCard: {
      position: "relative",
      top: "-33px",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)",
      borderRadius: "20px",
      width: "100%",
      margin: "0 auto",
      paddingTop: "21px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      zIndex: 2,
    },
    input: {
      marginBottom: "12px",
    },
    termsWrapper: {
      display: "flex",
      alignItems: "flex-start",
    },
    logo: {
      width: "39px",
      height: "37px",
      display: "block",
      margin: "0 auto",
      position: "relative",
      top: "13px",
    },
    root: {
      marginTop: "96px",
      background: `${theme.palette.primary.main}20`,
      height: "75vh",
      position: "relative",
      minHeight: "558px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        padding: "64px 0px 36px",
        marginTop: "96px",
      },
    },
    cumploWave: {
      position: "absolute",
      bottom: 0,
    },
    titleContainer: {
      paddingRight: "30%",
      [theme.breakpoints.down("lg")]: {
        paddingRight: "15%",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0px 0px 48px 0px",
        textAlign: "center",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalCard: {
      borderRadius: "20px",
      padding: "24px 0px 48px",
      width: "514px",
      zIndex: 0,
      margin: 2,
    },
    modalCardCloseButton: {
      width: "100%",
      textAlign: "right",
      paddingRight: 2,
      display: "flex",
      justifyContent: "flex-end",
    },
    modalCardTitle: {
      width: "416px !important",
      paddingBottom: 3,
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      rowGap: 3,
      alignItems: "center",
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const errors = Object.values(state.errors).some(
    (err) => Boolean(err) === true
  );
  const [loggedIn, setLoggedIn] = useState(0);
  const [sites, setSites] = useState([]);
  const client = useApolloClient();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location?.state?.text) {
      dispatch({
        type: "setState",
        state: {
          message: {
            text: location?.state?.text,
            type: "success",
          },
        },
      });
    }
  }, [location]);

  useEffect(() => {
    axios
      .get(`${Settings.secureUrl}/api/auth/validate_session/`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response?.status === 200) {
          client.writeQuery({
            query: CLIENT_IS_LOGGED_IN,
            data: {
              isLoggedIn: true,
            },
          });
          setLoggedIn(1);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          client.writeQuery({
            query: CLIENT_IS_LOGGED_IN,
            data: {
              isLoggedIn: false,
            },
          });
          setLoggedIn(0);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clSecureLogin = (e) => {
    e.preventDefault();
    dispatch({
      type: "setState",
      state: {
        loading: true,
      },
    });
    // TODO: Change environment
    axios
      .post(
        `${Settings.secureUrl}/api/auth/sign_in/`,
        {
          email: state.values.email,
          password: state.values.password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem("token", getCookie("_new_cumplo_session"));
          client.writeQuery({
            query: CLIENT_IS_LOGGED_IN,
            data: {
              isLoggedIn: true,
            },
          });
          const data = client.readQuery({
            query: CLIENT_IS_LOGGED_IN,
          });
          if (data?.isLoggedIn === true) {
            setTimeout(() => {
              setLoggedIn(1);
            }, 500);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: "setState",
          state: {
            loading: false,
            message: {
              text: "La contraseña ingresada es incorrecta.",
              type: "error",
            },
          },
        });
      });
  };

  const smartLogin = (region) => {
    if (!validations.validateEmail(state.values.email).error_email) {
      dispatch({
        type: "setState",
        state: {
          loading: true,
          message: {
            text: "",
            type: undefined,
          },
        },
      });
      // TODO: Change environment
      axios
        .get(`https://api.cumplo.com/auth/sites/?email=${state.values.email}`)
        .then((response) => {
          if (response?.status === 200) {
            // The user is not registered
            if (
              response?.data?.sites?.filter((site) => site?.country === region)
                .length === 0
            ) {
              dispatch({
                type: "setState",
                state: {
                  loading: false,
                  message: {
                    text: "El usuario no se encuentra registrado.",
                    type: "error",
                  },
                },
              });
              // The user is registered in one website
            } else if (
              response?.data?.sites?.filter((site) => site?.country === region)
                .length === 1
            ) {
              if (
                response?.data?.sites?.filter(
                  (site) => site?.country === region
                )[0]?.customer_type === "inversionistas"
              ) {
                // Si el usuario es solo inversionista en Chile
                if (
                  response?.data?.sites?.filter(
                    (site) => site?.country === region
                  )[0]?.country === "CL"
                ) {
                  window.location.replace(
                    `https://secure.cumplo.cl/users/login/?email=${state.values.email}`
                  );
                }
                // Si el usuario es solo inversionista en México
                if (
                  response?.data?.sites?.filter(
                    (site) => site?.country === region
                  )[0]?.country === "MX"
                ) {
                  if (
                    response?.data?.sites?.filter(
                      (site) => site?.country === region
                    )[0]?.name === "Inversionistas CumploMex"
                  ) {
                    window.location.replace(
                      `https://secure.cumplo.mx/login/?email=${state.values.email}`
                    );
                  } else if (
                    response?.data?.sites?.filter(
                      (site) => site?.country === region
                    )[0]?.name === "Inversionistas Ekscelenko"
                  ) {
                    window.location.replace(
                      `https://sofom.cumplo.mx/login/?email=${state.values.email}`
                    );
                  }
                }
                // Si el usuario no es inversionista
              } else {
                window.location.replace(
                  `${
                    response?.data?.sites?.filter(
                      (site) => site?.country === region
                    )[0]?.login
                  }/?email=${state.values.email}`
                );
              }
              // The user is registered on multiple websites
            } else {
              dispatch({
                type: "setState",
                state: {
                  loading: false,
                },
              });
              setSites(response?.data?.sites);
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: "setState",
            state: {
              loading: false,
              message: {
                text: "El correo ingresado es incorrecto.",
                type: "error",
              },
            },
          });
        });
    } else {
      dispatch({
        type: "setState",
        state: {
          loading: false,
          message: {
            text: "Ingresa tu correo electrónico.",
            type: "error",
          },
        },
      });
    }
  };

  const loginInterface = (e) => {
    e.preventDefault();
    dispatch({
      type: "setState",
      state: {
        loading: true,
      },
    });
    const region =
      process.env.GATSBY_REACT_APP_REGION === "es-CL" ? "CL" : "MX";
    smartLogin(region);
  };

  const getOptionsCL = (site) => {
      const urlSite = site?.customer_type !== "inversionistas" ? site?.login : `${process.env.GATSBY_SECURE_SITIO}/users/login`;

      window.location.replace(`${urlSite}/?email=${state.values.email}`);
  };

  const getOptionsMX = (site) => {
    window.location.replace(`${site?.login}/?email=${state.values.email}`);
  };

  return (
    <>
      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
        <Modal
          open={open}
          onClose={handleClose}
          sx={styles.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card sx={styles.modalCard}>
              <Box onClick={handleClose} sx={styles.modalCardCloseButton}>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="close"
                    color={theme.palette.secondary.main}
                    height="40px"
                  />
                </div>
              </Box>
              <Box sx={styles.modalContent}>
                <Typography
                  variant="h4"
                  align="center"
                  fontWeight="500"
                  color="primary.dark"
                  sx={styles.modalCardTitle}
                >
                  Elige una forma de registro
                </Typography>
                <a
                  href="https://financiamiento.cumplo.cl/registro"
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained">PyMe</Button>
                </a>
                <a
                  href="https://prontopago.cumplo.cl/registro/clientes"
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained">Corporaciones</Button>
                </a>
                <a
                  href="https://secure.cumplo.cl/users/sign_up"
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained">Inversionista</Button>
                </a>
              </Box>
            </Card>
          </Fade>
        </Modal>
      )}
      {loggedIn === 0 && (
        <>
          <Grid
            sx={styles.root}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={11} sm={8} md={6}>
              <Box sx={styles.titleContainer}>
                <Typography variant="h1" color="primary.main" display="inline">
                  Ingresa a tu cuenta en Cumplo,
                </Typography>
                <Typography variant="h1" color="primary.dark" display="inline">
                  {process.env.GATSBY_REACT_APP_REGION === "es-MX"
                    ? ` la red de financiamiento `
                    : ` la red de financiamiento colaborativo `}
                </Typography>
                <Typography variant="h1" color="primary.main" display="inline">
                  con mayor impacto en Latinoamérica.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={11} sm={9} md={4}>
              <Box sx={styles.cumploIcon}>
                <Logo
                  variant={"isotype"}
                  size={40}
                  color={theme.palette.primary.main}
                />
              </Box>
              <Card sx={styles.registerCard}>
                <CardContent>
                  {state.message.text !== "" &&
                    state.message.text !== undefined && (
                      <Collapse in={state.message.text !== ""}>
                        <Alert
                          style={{
                            alignItems: "center",
                          }}
                          severity={state.message.type}
                          action={
                            <IconButton
                              aria-label="close"
                              size="small"
                              onClick={() =>
                                dispatch({
                                  type: "setState",
                                  state: {
                                    message: {
                                      text: "",
                                      type: undefined,
                                    },
                                  },
                                })
                              }
                            >
                              <Icon
                                name="close"
                                color={theme.palette.secondary.main}
                                height="24px"
                              />
                            </IconButton>
                          }
                        >
                          {state.message.text}
                        </Alert>
                      </Collapse>
                    )}
                  <div
                    style={{
                      paddingTop: "24px",
                    }}
                  />
                  {sites.length === 0 && (
                    <>
                      <Typography
                        align="center"
                        variant="subtitle1"
                        color="primary.dark"
                      >
                        Ingresa tu correo electrónico:
                      </Typography>
                      <div
                        style={{
                          paddingTop: "21px",
                        }}
                      />
                      {/* Formulario */}
                      <form noValidate>
                        <Grid container sx={{ textAlign: "center" }}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <TextField
                                  type="email"
                                  name="email"
                                  id="standard-basic"
                                  label="Correo electrónico"
                                  autoFocus
                                  fullWidth
                                  value={state.values.email}
                                  error={state.errors.error_email !== false}
                                  onChange={(e) =>
                                    dispatch({
                                      type: "setValue",
                                      value: {
                                        email: e.target.value,
                                      },
                                    })
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                />
                                <FormHelperText id="component-error-text">
                                  {state.errors.error_email &&
                                    "Ingresar correo electrónico"}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingTop: "21px",
                                }}
                              >
                                <Button
                                  disabled={errors}
                                  loading={state.loading}
                                  variant="contained"
                                  type="submit"
                                  action={loginInterface}
                                >
                                  Ingresar
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
                            <Grid item xs={12}>
                              <Grid container justify="center">
                                <Grid item xs={12}>
                                  <Box sx={{ marginTop: 3 }}>
                                    <Typography variant="body1">
                                      ¿No tienes cuenta en Cumplo?
                                    </Typography>
                                  </Box>
                                  <Button variant={"text"} action={handleOpen}>
                                    Regístrate
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </form>
                    </>
                  )}
                  {sites.length === 1 && (
                    <>
                      <Typography
                        align="center"
                        color="primary.dark"
                        variant="subtitle1"
                      >
                        Ingresa tu contraseña:
                      </Typography>
                      <div
                        style={{
                          paddingTop: "21px",
                        }}
                      />
                      <form noValidate>
                        <Grid container sx={{ textAlign: "center" }}>
                          <Grid item xs={12}>
                            <Grid container justify="space-between">
                              <Grid item xs={12}>
                                <TextField
                                  name="password"
                                  type="password"
                                  id="standard-basic"
                                  label="Contraseña"
                                  autoFocus
                                  fullWidth
                                  value={state.values.password}
                                  error={state.errors.error_password !== false}
                                  onChange={(e) =>
                                    dispatch({
                                      type: "setValue",
                                      value: {
                                        password: e.target.value,
                                      },
                                    })
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                />
                                <FormHelperText id="component-error-text">
                                  {state.errors.error_password &&
                                    "Ingresar clave"}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingTop: "21px",
                                }}
                              >
                                <Button
                                  disabled={errors}
                                  loading={state.loading}
                                  variant="contained"
                                  type="submit"
                                  action={clSecureLogin}
                                >
                                  Ingresar
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="center">
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    marginTop: "21px",
                                    marginBottom: "4px",
                                  }}
                                />
                                <Grid item xs={12}>
                                  <Grid container justify="center">
                                    <Grid item xs={12}>
                                      <Link
                                        to="/recuperar-contrasena"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Button variant={"text"}>
                                          ¿Olvidaste tu contraseña?
                                        </Button>
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </>
                  )}
                  {sites.length > 1 && (
                    <>
                      <Typography
                        align="center"
                        color="primary.dark"
                        variant="subtitle1"
                      >
                        Selecciona el sitio a ingresar:
                      </Typography>
                      <div
                        style={{
                          paddingTop: "21px",
                        }}
                      />
                      {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
                        <List>
                          {sites
                            ?.filter((site) => site?.country === "CL")
                            .map((site) => (
                              <ListItem
                                key={site.name}
                                sx={{ margin: "0 auto" }}
                              >
                                <Button
                                  disabled={errors}
                                  loading={state.loading}
                                  variant="contained"
                                  type="submit"
                                  action={() => getOptionsCL(site)}
                                  style={{
                                    margin: "8px 0px",
                                    width: "100%",
                                  }}
                                >
                                  {site.name}
                                </Button>
                              </ListItem>
                            ))}
                        </List>
                      )}
                      {process.env.GATSBY_REACT_APP_REGION === "es-MX" && (
                        <List>
                          {sites
                            ?.filter((site) => site?.country === "MX")
                            .map((site) => (
                              <ListItem
                                key={site.name}
                                sx={{ margin: "0 auto" }}
                              >
                                <Button
                                  disabled={errors}
                                  loading={state.loading}
                                  variant="contained"
                                  type="submit"
                                  action={() => getOptionsMX(site)}
                                  style={{
                                    margin: "8px 0px",
                                    width: "100%",
                                  }}
                                >
                                  {site.name}
                                </Button>
                              </ListItem>
                            ))}
                        </List>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      {loggedIn === 1 && (
        <>
          {window.location.replace(`${Settings.secureUrl}/`)}
          <Grid
            sx={styles.root}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={11} sm={8} md={6}>
              <Box sx={styles.titleContainer}>
                <Typography variant="h1" color="primary.main" display="inline">
                  Ingresa a tu cuenta en Cumplo,
                </Typography>
                <Typography variant="h1" color="primary.dark" display="inline">
                  {process.env.GATSBY_REACT_APP_REGION === "es-MX"
                    ? ` la red de financiamiento `
                    : ` la red de financiamiento colaborativo `}
                </Typography>
                <Typography variant="h1" color="primary.main" display="inline">
                  con mayor impacto en Latinoamérica.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={11} sm={9} md={4}>
              <Box sx={styles.cumploIcon}>
                <Logo
                  variant={"isotype"}
                  size={40}
                  color={theme.palette.primary.main}
                />
              </Box>
              <Card sx={styles.registerCard}>
                <CardContent>
                  {state.message.text !== "" &&
                    state.message.text !== undefined && (
                      <Collapse in={state.message.text !== ""}>
                        <Alert
                          style={{
                            alignItems: "center",
                          }}
                          severity={state.message.type}
                          action={
                            <IconButton
                              aria-label="close"
                              size="small"
                              onClick={() =>
                                dispatch({
                                  type: "setState",
                                  state: {
                                    message: {
                                      text: "",
                                      type: undefined,
                                    },
                                  },
                                })
                              }
                            >
                              <Icon
                                name="close"
                                color={theme.palette.secondary.main}
                                height="24px"
                              />
                            </IconButton>
                          }
                        >
                          {state.message.text}
                        </Alert>
                      </Collapse>
                    )}
                  <div
                    style={{
                      paddingTop: "24px",
                    }}
                  />
                  {sites.length === 1 && (
                    <>
                      <Typography
                        align="center"
                        color="primary.dark"
                        variant="subtitle1"
                      >
                        Ingresa tu contraseña:
                      </Typography>
                      <div
                        style={{
                          paddingTop: "21px",
                        }}
                      />
                      <form noValidate>
                        <Grid container sx={{ textAlign: "center" }}>
                          <Grid item xs={12}>
                            <Grid container justify="space-between">
                              <Grid item xs={12}>
                                <TextField
                                  name="password"
                                  type="password"
                                  id="standard-basic"
                                  label="Contraseña"
                                  autoFocus
                                  fullWidth
                                  value={state.values.password}
                                  error={state.errors.error_password !== false}
                                  onChange={(e) =>
                                    dispatch({
                                      type: "setValue",
                                      value: {
                                        password: e.target.value,
                                      },
                                    })
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: theme.palette.text.primary,
                                    },
                                  }}
                                />
                                <FormHelperText id="component-error-text">
                                  {state.errors.error_password &&
                                    "Ingresar clave"}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  paddingTop: "21px",
                                }}
                              >
                                <Button
                                  disabled={errors}
                                  loading={state.loading}
                                  variant="contained"
                                  type="submit"
                                  action={clSecureLogin}
                                >
                                  Ingresar
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="center">
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    marginTop: "21px",
                                    marginBottom: "4px",
                                  }}
                                />
                                <Grid item xs={12}>
                                  <Grid container justify="center">
                                    <Grid item xs={12}>
                                      <Link
                                        to="/recuperar-contrasena"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Button variant={"text"}>
                                          ¿Olvidaste tu contraseña?
                                        </Button>
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
